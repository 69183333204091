export default class BrockmanTwitchPlayer {
  /**
   * Render a YouTube video player for the provided channel, using a mirror of the channel's YouTube XML feed to provide the playlist data.
   * @param {Element} args.target - Container in which to render the video player.
   * @param {String} args.endpoint - URL of the json view to source the twitch stream data.
   * @param {Object} [args.loc] - Localisation strings, used to customize text per site. Can be overridden individually.
   */

  constructor(args) {
    // Args
    this.target = args.target;
    this.endpoint = args.endpoint;
    this.twitchEmbedId = args.twitchEmbedId || 'twitch-embed';

    this.player = null;

    this.loc = args.loc || {
      label: 'Live',
      playing: 'playing',
    };
  }

  run() {
    this.fetchChannels();
  }

  destroy() {
    this.target.remove();
  }

  fetchChannels() {
    fetch(this.endpoint)
      .then((response) => {
        if (!response.ok) throw new Error('Response status not ok');
        return response;
      })
      .then((response) => response.json())
      .then((json) => {
        if (!json || !('data' in json) || !json.data.length) this.destroy();
        else this.buildPlayer(json.data[0]);
      })
      .catch(() => this.destroy());
  }

  buildPlayer(data) {
    if (!('Twitch' in window)) {
      const tag = document.createElement('script');
      tag.src = 'https://player.twitch.tv/js/embed/v1.js';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      tag.onload = this.createTwitchPlayer.bind(this, data);
    } else {
      this.createTwitchPlayer(data);
    }
  }

  createTwitchPlayer(data) {
    if (!['name', 'user_name', 'game_name'].every((key) => key in data)) {
      this.destroy();
      return;
    }

    this.target.innerHTML = `
      <h2 class="section_title">
        <span>${this.loc.label}: </span> ${data.user_name} ${this.loc.playing} ${data.game_name}
      </h2>
      <div class="twitch_wrapper">
        <div id="${this.twitchEmbedId}"></div>
      </div>
    `;

    this.target.classList.add('loaded');

    this.player = new window.Twitch.Player(this.twitchEmbedId, {
      channel: data.name,
      layout: 'video',
      autoplay: true,
      muted: true,
    });
  }
}
