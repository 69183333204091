import { getCountryForTimezone } from 'countries-and-timezones';

export const getCountryCode = () => {
  if (Intl) {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const country = getCountryForTimezone(timeZone);
    if (country) return country.id;
  }
  return 'UNKNOWN';
};
