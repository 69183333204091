export default class BrockmanLazyLoad {
  constructor() {
    this.selector = 'script[data-lazyload=true]';
    this.observer = null;
    this.observedElements = [];

    if ('IntersectionObserver' in window) {
      this.observer = new IntersectionObserver(this.observe.bind(this));
      window.addEventListener('BrockmanTargetingCookiesAllowed', this.refresh.bind(this));
      window.addEventListener('BrockmanTargetingCookiesDisallowed', this.run.bind(this));
    }
  }

  run() {
    const targets = document.querySelectorAll(this.selector);
    this.registerElements(targets);
  }

  registerElements(targets) {
    const elements = [].slice.call(targets);
    for (let i = 0; i < elements.length; i += 1) {
      if (this.observer) {
        this.register(elements[i].parentElement);
      } else {
        // Fallback for browsers which don't support IntersectionObserver.
        this.load(elements[i].parentElement);
      }
    }
  }

  register(target) {
    const { cookiesSrc } = target.querySelector('script').dataset;
    if (cookiesSrc) {
      this.observer.observe(target);
      this.observedElements.push(target);
    }
  }

  deregister(target) {
    this.observer.unobserve(target);
    this.observedElements.splice(this.observedElements.indexOf(target), 1);
  }

  observe(entries) {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) return;
      const { target } = entry;
      this.load(target);
    });
  }

  refreshScripts() {
    // destroy old bbw players if present
    if (window.bluebillywig && window.bluebillywig.players) {
      window.bluebillywig.players.reduceRight((_, player) => player.destruct(), null);
    }
    const elements = document.querySelectorAll(this.selector);
    for (let i = 0; i < elements.length; i += 1) {
      const element = elements[i].parentElement;
      const script = element.querySelector('script');
      if (script.hasAttribute('src')) {
        const clonedScript = document.createElement('script');
        clonedScript.dataset.cookiesSrc = script.dataset.cookiesSrc;
        clonedScript.dataset.noCookiesSrc = script.dataset.noCookiesSrc;
        clonedScript.dataset.lazyload = true;
        if (script.getAttribute('onload')) {
          clonedScript.setAttribute('onload', script.getAttribute('onload'));
        }
        element.innerHTML = '';
        element.append(clonedScript);
      }
    }
  }

  refresh() {
    for (let i = 0; i < this.observedElements.length; i += 1) {
      const element = this.observedElements[i];
      this.observer.unobserve(element);
    }
    this.observedElements = [];
    this.refreshScripts();
    this.run();
  }

  isTargetingAllowed() {
    // return targeting cookie permissions
    return window.BrockmanAllowedCookies.targeting;
  }

  load(target) {
    const targetElement = target.querySelector('script');
    const { cookiesSrc, nocookiesSrc } = targetElement.dataset;

    if (cookiesSrc && this.isTargetingAllowed()) {
      targetElement.src = cookiesSrc;
    } else if (nocookiesSrc) {
      targetElement.src = nocookiesSrc;
    }

    if (!this.observer) return;
    this.deregister(target);
  }
}
