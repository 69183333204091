export const setCookie = (name, value, expiresDays = 0, secure = true, extraOptions = {}) => {
  if (!name) return;

  const options = {
    path: '/',
    secure,
    ...extraOptions,
  };

  if (expiresDays) {
    const expires = new Date();
    expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000 * expiresDays);
    options.expires = expires.toUTCString();
  }

  let cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  Object.entries(options).forEach(([key, val]) => {
    cookie += `; ${key}`;
    if (val !== true) cookie += `=${val}`;
  });

  document.cookie = cookie;
};

export const deleteCookie = (name) => {
  setCookie(name, '', -1);
};

export const getCookie = (name) => {
  const search = new URLSearchParams(document.cookie.replaceAll('; ', '&'));
  return search.get(name) || undefined;
};
