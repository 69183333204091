export default class BrockmanCommentCountLoad {
  constructor(args) {
    this.endpoint = args.endpoint;
    this.run();
  }

  run() {
    const threads = [];
    const elements = {};

    document.querySelectorAll('.comment-count-load').forEach((el) => {
      const { thread } = el.dataset;
      if (threads.indexOf(thread) === -1) threads.push(thread);
      if (!(thread in elements)) elements[thread] = [];
      elements[thread].push(el);
    });

    if (threads.length === 0) return;
    threads.sort((a, b) => a - b);

    const url = new URL(this.endpoint, window.location.origin);
    url.searchParams.set('threads', threads.join(','));

    fetch(new Request(url), {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        Object.entries(response).forEach(([thread, count]) => {
          elements[thread].forEach((el) => {
            el.querySelector('.comments__count').textContent = parseInt(count, 10).toLocaleString();
            if (count !== 0) el.classList.remove('skeleton');
          });
        });
      })
      .catch(() => {
        document.querySelectorAll('.comment-count-load').forEach((el) => {
          el.classList.remove('skeleton');
        });
      });
  }
}
