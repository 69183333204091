export default class BrockmanInterestsStorage {
  constructor(args) {
    this.csrf = args.csrf;
    this.site = args.site;
    this.loggedIn = args.logged_in;

    this.allFollowsEndpoint = args.all_follows_url;
    this.followEndpoint = args.follow_url;
    this.followWithSuggestedEndpoint = args.follow_with_suggested_url;
    this.unfollowEndpoint = args.unfollow_url;

    this.loaded = false;
    this.storage_key = this.getStorageKey();

    this.initialize();
  }

  initialize() {
    if (!this.loggedIn) {
      this.loaded = true;
      this.resetStorageKey();
      return;
    }

    if (sessionStorage.getItem(this.storage_key)) {
      this.loaded = true;
    }
  }

  resetStorageKey() {
    if (!sessionStorage.getItem(this.storage_key)) {
      sessionStorage.setItem(this.storage_key, '');
    }
  }

  getStorageKey() {
    return this.loggedIn ? 'interests_logged_in' : 'interests_logged_out';
  }

  getFollows() {
    if (this.loaded) {
      const uuids = sessionStorage.getItem(this.storage_key);
      if (uuids.length > 0) {
        return Promise.resolve(uuids.split(','));
      }

      return Promise.resolve([]);
    }

    return fetch(new Request(this.allFollowsEndpoint), {
      method: 'GET',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
      .then((markup) => markup.json())
      .then((data) => {
        if ('following' in data) {
          sessionStorage.setItem(this.storage_key, data.following.toString());
          this.loaded = true;
          if (data.following.length > 0) {
            return data.following;
          }
        }
        return [];
      })
      .catch(() => {
        this.loaded = true;
        return [];
      });
  }

  getFollowEndpoint(withSuggested) {
    return withSuggested ? this.followWithSuggestedEndpoint : this.followEndpoint;
  }

  followUUIDs(uuids, withSuggested = false) {
    if (!this.loaded) {
      return this.getFollows().then(() => {
        this.followUUIDs(uuids);
      });
    }
    if (!this.loggedIn) {
      return Promise.resolve(this.addUUIDsToStorage(uuids));
    }
    const formData = new FormData();
    formData.append('tags', uuids.toString());
    formData.append('site', this.site);
    const endpoint = this.getFollowEndpoint(withSuggested);
    return fetch(new Request(endpoint), {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRFToken': this.csrf,
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) return response;
        throw new Error();
      })
      .then((response) => response.json())
      .then((data) => {
        const { markup } = data;
        this.addUUIDsToStorage(uuids);
        return { uuids, markup };
      });
  }

  unfollowUUID(uuid) {
    if (!this.loaded) {
      return this.getFollows().then(() => {
        this.unfollow(uuid);
      });
    }
    if (!this.loggedIn) {
      return Promise.resolve(this.removeUUIDFromStorage(uuid));
    }
    const formData = new FormData();
    formData.append('tags', uuid);
    formData.append('site', this.site);
    return fetch(new Request(this.unfollowEndpoint), {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRFToken': this.csrf,
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) return response;
        throw new Error();
      })
      .then(() => {
        this.removeUUIDFromStorage(uuid);
        return [uuid];
      });
  }

  addUUIDsToStorage(uuids) {
    const current = sessionStorage.getItem(this.storage_key).split(',').filter(Boolean);
    if (this.loggedIn && current.length === 0) {
      this.toggleFirstFollowModal();
    }
    sessionStorage.setItem(this.storage_key, [...new Set(current.concat(uuids))].toString());
    return uuids;
  }

  removeUUIDFromStorage(uuid) {
    const current = sessionStorage.getItem(this.storage_key).split(',');
    const index = current.indexOf(uuid);
    if (index > -1) {
      current.splice(index, 1);
    }
    sessionStorage.setItem(this.storage_key, current.toString());
    return uuid;
  }

  toggleFirstFollowModal() {
    if (this.firstFollowModal) {
      this.firstFollowModal.classList.toggle('show');
    }
  }
}
