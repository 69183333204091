export default class BrockmanTableCheckboxes {
  constructor(containerIdentifier) {
    this.containerIdentifier = containerIdentifier || 'body';
    this.checkboxes = document.querySelectorAll(
      `${this.containerIdentifier} .table-checkboxes .checkbox input:not([name="all"])`,
    );
    [this.mainCheckbox] = document.querySelectorAll(
      `${this.containerIdentifier} .table-checkboxes .checkbox input[name="all"]`,
    );
    this.startCheckboxes();
  }

  startCheckboxes() {
    if (this.mainCheckbox) {
      this.mainCheckbox.addEventListener('click', () => {
        if (this.mainCheckbox.checked) {
          this.checkboxes.forEach((input) => {
            input.checked = true;
          });
        } else {
          this.checkboxes.forEach((input) => {
            input.checked = false;
          });
        }
      });
    }
  }
}
