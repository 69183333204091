export default class BrockmanCarousel {
  /**
   * Set up a "carousel", which allows the user to navigate through overflowing elements using previous / next "step"
   * buttons, and displays the current page location using "page" buttons (which can also be used to navigate). Designed
   * to be used with the "carousel.j2" template and "carousel.less" styles.
   *
   * @param {Object} args - The settings to use when building the carousel.
   * @param {Element} args.target - The parent container to search for other elements within.
   * @param {string} [args.containerSelector] - The query to select the carousel scrolling container.
   * @param {string} [args.itemSelector] - The query to select the carousel items within the container.
   * @param {string} [args.indicatorSelector] - The query to select the buttons for changing page. These elements must
   *   have a `data-index` set to the page number.
   * @param {string} [args.previousButtonSelector] - The query to select the button for scrolling the carousel left.
   * @param {string} [args.nextButtonSelector] - The query to select the button for scrolling the carousel right.
   * @param {number} [args.scrollEndLeeway] - The duration in milliseconds after the scroll event stops firing to wait
   *   before calling the after scroll event.
   */
  constructor({
    target,
    containerSelector,
    itemSelector,
    indicatorSelector,
    previousButtonSelector,
    nextButtonSelector,
    scrollEndLeeway,
    maxIndicators,
  }) {
    this.target = target;
    this.containerSelector = containerSelector || '.carousel_items';
    this.itemSelector = itemSelector || '.carousel_items > *';
    this.indicatorSelector = indicatorSelector || '.carousel_indicator';
    this.previousButtonSelector = previousButtonSelector || '.button.left';
    this.nextButtonSelector = nextButtonSelector || '.button.right';
    this.scrollEndLeeway = scrollEndLeeway || 50;
    this.maxIndicators = maxIndicators || 8;

    this.container = this.target.querySelector(this.containerSelector);
    this.previous = this.target.querySelector(this.previousButtonSelector);
    this.next = this.target.querySelector(this.nextButtonSelector);
    this.items = [...this.container.querySelectorAll(this.itemSelector)];
    this.indicators = [...this.target.querySelectorAll(this.indicatorSelector)];

    this.maxIndex = 0;
    this.currentIndex = 0;
    this.paginationButtons = [];
    this.observer = null;
    this.scrollTimeoutHandler = null;

    this.register();
    this.observe();
  }

  /**
   * Set up the carousel events for pagination (clicking the previous / next buttons or pages) and update page buttons
   * on scroll.
   */
  register() {
    this.container.addEventListener('scroll', () => this.onScroll(), {
      passive: true,
    });
    if (this.next) this.next.addEventListener('click', () => this.scrollTo(this.currentIndex + 1));
    if (this.previous) this.previous.addEventListener('click', () => this.scrollTo(this.currentIndex - 1));
    this.indicators.forEach((item) =>
      item.addEventListener('click', () => this.scrollTo(parseInt(item.dataset.index, 10))),
    );
  }

  /**
   * Scroll the carousel to element with the specified index.
   *
   * @param {number} index - The element index to scroll to. Must be an integer.
   */
  scrollTo(index) {
    if (index < 0 || index > this.maxIndex) return;
    this.container.scrollLeft = this.items[index].offsetLeft;
  }

  /** Detect when the carousel is scrolled and debounce when the scroll stops. */
  onScroll() {
    if (this.scrollTimeoutHandler) clearTimeout(this.scrollTimeoutHandler);
    this.scrollTimeoutHandler = setTimeout(() => this.onScrollEnd(), this.scrollEndLeeway);
  }

  /** When the carousel finishes scrolling, re-render the buttons to update active and disabled states. */
  onScrollEnd() {
    this.currentIndex = this.getFirstVisibleIndex();
    this.updateButtons();
  }

  /** Re-render the previous / next buttons, and the page indicator dot buttons. */
  updateButtons() {
    this.updateStepButtons();
    this.updateIndicators();
  }

  /**
   * Toggle the "disabled" attribute for the previous / next buttons when the carousel is fully scrolled to the start or
   * end.
   */
  updateStepButtons() {
    if (this.previous) this.previous.disabled = this.currentIndex === 0;
    if (this.next) this.next.disabled = this.currentIndex === this.maxIndex;
  }

  /**
   * Hide dot indicators if they exceed the maximum index, and show them if not. Toggle "active" class for the button
   * corresponding to the first fully visible carousel element.
   */
  updateIndicators() {
    this.indicators.forEach((item) => {
      const index = parseInt(item.dataset.index, 10);
      const button = item.querySelector('.button');
      if (index > this.maxIndex) item.classList.add('hidden');
      else item.classList.remove('hidden');
      if (index === this.currentIndex) button.classList.add('active');
      else button.classList.remove('active');
    });

    this.target.dataset.hideIndicators = this.maxIndex >= this.maxIndicators;
  }

  /** On resize update the maximum index, as it can change depending on the width of the container. */
  onResize() {
    this.maxIndex = this.getMaxIndex();
    this.target.dataset.maxIndex = this.maxIndex;
    this.updateButtons();
  }

  /** Check for window resize. */
  observe() {
    if ('ResizeObserver' in window) {
      this.observer = new ResizeObserver((entries) => entries.forEach(() => this.onResize()));
      this.observer.observe(this.container);
    } else {
      this.onResize();
      window.addEventListener('resize', () => this.onResize());
    }
  }

  /**
   * Get the maximum index which can be scrolled to (ie the index of the first visible element to the left, when the
   * carousel is scrolled fully to the right).
   */
  getMaxIndex() {
    let previousIndex = this.items.length - 1;
    for (let index = previousIndex; index >= 0; index -= 1) {
      const distanceFromEnd = this.container.scrollWidth - this.items[index].offsetLeft;
      if (distanceFromEnd > this.container.offsetWidth) return previousIndex;
      previousIndex = index;
    }

    return 0; // All items are visible
  }

  /** Get the index of the first visible element to the left of the carousel. */
  getFirstVisibleIndex() {
    const { scrollLeft } = this.container;
    return this.items.findIndex((item) => item.offsetLeft >= scrollLeft);
  }
}
