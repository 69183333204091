export default class BrockmanVideoPlayer {
  /**
   * Render a YouTube video player for the provided channel, using a mirror of the channel's YouTube XML feed to provide the playlist data.
   * @param {Element} args.target - Container in which to render the video player.
   * @param {String} args.endpoint - URL of the XML feed from which to source the playlist data.
   * @param {String} args.channelId - YouTube channel ID (see https://support.google.com/youtube/answer/3250431).
   * @param {Boolean} args.adsEnabled - If true then allow BBVMS ads (if cookies are also enabled).
   * @param {Boolean} [args.autoLoad] - If true then load immediately on page load - if false then load when scrolling into viewport.
   * @param {String} [args.endpointBBVMS] - URL of the BBVMS search endpoint.
   * @param {String} [args.playoutBBVMS] - The playout ID to use for BBVMS videos.
   * @param {String} [args.typeBBVMS] - The type of BBVMS videos to render.
   * @param {Number} [args.descriptionLength] - Max length for the video description (will be truncated with a &hellip;). Must be an integer. Set to 0 to hide description.
   * @param {Object} [args.loc] - Localisation strings, used to customize text per site. Can be overridden individually.
   * @param {String} [args.spinnerClassList] - Classes to use for the video loading spinner.
   */

  constructor(args) {
    // Args
    this.target = args.target;
    this.endpoint = args.endpoint;
    this.channelId = args.channelId;
    this.adsEnabled = args.adsEnabled;

    // Optional args
    this.autoLoad = !!args.autoLoad;
    this.endpointBBVMS = args.endpointBBVMS || 'https://gamernetwork.bbvms.com/json/search';
    this.playoutBBVMS = args.playoutBBVMS || null;
    this.typeBBVMS = args.typeBBVMS || 'c';
    this.spinnerClassList = args.spinnerClassList || 'spinner large inverted';

    // If descriptionLength is 0 then don't show a description
    this.descriptionLength = typeof args.descriptionLength === 'number' ? args.descriptionLength : 500;

    this.loc = {
      loading: 'Loading',
      seeMore: 'See more %1% videos', // %1% is the channel name
      subscribe: 'Subscribe',
      thumbnail: '%1% thumbnail', // %1% is the video title
      error: 'Sorry, there was an error loading the video player.',
    };

    // Update localisation from args
    if (args.loc) {
      Object.keys(this.loc).forEach((key) => {
        if (args.loc[key]) this.loc[key] = args.loc[key];
      });
    }

    // Elements
    this.observer = null;
    this.playlist = [];
    this.videoHolder = null;
    this.videoWrapper = null;

    // Data
    this.channel = {};
    this.videos = [];
  }

  static isTargetingAllowed() {
    // Wait for cookie permissions to be established
    if (!window.BrockmanAllowedCookies) return false;
    // Check targeting cookie permissions
    return window.BrockmanAllowedCookies.targeting;
  }

  static getIdBBVMS(json) {
    if (!('items' in json)) return null;
    if (!json.items.length) return null;
    if (!('id' in json.items[0])) return null;
    return json.items[0].id;
  }

  run() {
    if (!this.target || !this.channelId) return;
    this.renderSpinner();
    if (this.autoLoad) this.fetch();
    else this.observe();
  }

  renderSpinner() {
    this.target.innerHTML = `<span class="${this.spinnerClassList}"></span>`;
  }

  observe() {
    if ('IntersectionObserver' in window) {
      this.observer = new IntersectionObserver((target) => this.intersect(target));
      this.observer.observe(this.target);
    } else this.fetch();
  }

  intersect(entries) {
    if (entries[0].isIntersecting) {
      this.fetch();
      this.observer.disconnect();
    }
  }

  fetch() {
    this.target.dataset.loaded = false;
    const endpoint = `${this.endpoint}?channel_id=${this.channelId}`;
    fetch(endpoint)
      .then((response) => {
        if (!response.ok) throw new Error('Response status not ok');
        return response;
      })
      .then((response) => response.text())
      .then((text) => this.parse(text))
      .catch(() => this.renderError());
  }

  parse(text) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(text, 'text/xml');

    // Parse channel information
    const author = xmlDoc.getElementsByTagName('author')[0];
    this.channel = {
      name: author.getElementsByTagName('name')[0].textContent,
      uri: author.getElementsByTagName('uri')[0].textContent,
    };

    // Parse video entries
    const entries = xmlDoc.getElementsByTagName('entry');
    for (let i = 0; i < entries.length; i += 1) {
      const entry = entries[i];
      const group = entry.getElementsByTagName('media:group')[0];
      const id = entry.getElementsByTagName('yt:videoId')[0].textContent;
      const title = entry.getElementsByTagName('title')[0].textContent;
      const mediaThumbnail = group.getElementsByTagName('media:thumbnail')[0];
      let thumbnail = mediaThumbnail.attributes.getNamedItem('url').nodeValue;
      thumbnail = thumbnail.replace(/\/hqdefault\.jpg$/, '/mqdefault.jpg');
      const description = group.getElementsByTagName('media:description')[0].textContent;
      this.videos.push({
        id,
        title,
        thumbnail,
        description,
      });
    }

    this.render();
  }

  truncateDescription(text) {
    const description = text.trim().split(/\r?\n/)[0];
    // Don't append &hellip; if description isn't too long
    if (description.length <= this.descriptionLength) return description;
    // Don't truncate words
    return `${description.substr(0, description.lastIndexOf(' ', this.descriptionLength))}&hellip;`;
  }

  render() {
    if (!this.channel || !this.videos) {
      this.renderError();
      return;
    }

    this.target.innerHTML = `
      ${this.getVideoEmbedMarkup()}
      ${this.getVideoPlaylistMarkup()}
    `;

    this.register();
    if (this.playlist) this.playlist[0].click();
    this.target.dataset.loaded = true;
  }

  getVideoEmbedMarkup() {
    return `
      <div class="video_embed">
        <div class="video_holder">
          <div class="spinner_wrapper">
            <span class="${this.spinnerClassList}"></span>
          </div>
        </div>
        <p class="title"></p>
        ${this.descriptionLength ? `<div class="description"></div>` : ``}
        <div class="button_group">
          ${this.getChannelButtonMarkup()}
          ${this.getSubscribeButtonMarkup()}
        </div>
      </div>
    `;
  }

  getVideoPlaylistMarkup() {
    let items = '';
    Object.keys(this.videos).forEach((key) => {
      items += this.getVideoPlaylistItemMarkup(this.videos[key]);
    });
    return `
      <div class="video_playlist">
        ${items}
      </div>
    `;
  }

  getVideoPlaylistItemMarkup(video) {
    return `
      <button
        class="playlist_item"
        data-id="${video.id}"
      >
        <span class="thumbnail">
          <img
            src="${video.thumbnail}"
            alt="${this.loc.thumbnail.replace('%1%', video.title)}"
          >
        </span>
        <span class="playlist_title">${video.title}</span>
      </button>
    `;
  }

  getChannelButtonMarkup() {
    return `
      <a
        href="${this.channel.uri}"
        class="button channel"
        target="_blank"
        rel="noopener"
      >
        ${this.loc.seeMore.replace('%1%', this.channel.name)}
      </a>
    `;
  }

  getSubscribeButtonMarkup() {
    return `
      <a
        href="${this.channel.uri}?sub_confirmation=1"
        class="button outline subscribe"
        target="_blank"
        rel="noopener"
      >
        ${this.loc.subscribe}
      </a>
    `;
  }

  renderTitleLink(video) {
    this.title.innerHTML = `
      <a
        href="https://www.youtube.com/watch/${video.id}"
        target="_blank"
        rel="noopener"
      >
        ${video.title}
      </a>
    `;
  }

  resetVideo() {
    this.videoHolder.dataset.loaded = false;
    if (this.videoWrapper) this.videoWrapper.remove();
    this.videoWrapper = document.createElement('div');
    this.videoWrapper.classList.add('video_wrapper');
    this.videoHolder.appendChild(this.videoWrapper);
  }

  renderDescription(video) {
    if (!this.description) return;
    this.description.innerHTML = this.truncateDescription(video.description);
  }

  renderIframe(src) {
    const iframe = document.createElement('iframe');
    iframe.src = src;
    iframe.setAttribute('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture');
    iframe.setAttribute('allowFullScreen', '');
    iframe.setAttribute('frameborder', '0');
    this.resetVideo();
    this.videoWrapper.appendChild(iframe);
    iframe.addEventListener('load', () => this.videoLoaded());
  }

  renderScript(src) {
    const script = document.createElement('script');
    script.src = src;
    this.resetVideo();
    this.videoWrapper.appendChild(script);
    script.addEventListener('load', () => this.videoLoaded());
  }

  videoLoaded() {
    this.videoHolder.dataset.loaded = true;
  }

  register() {
    this.videoHolder = this.target.querySelector('.video_embed .video_holder');
    this.title = this.target.querySelector('.video_embed .title');
    this.description = this.target.querySelector('.video_embed .description');

    // Playlist click events
    if (!this.playlist) return;
    this.playlist = Array.prototype.slice.call(this.target.querySelectorAll('.playlist_item[data-id]'));
    this.playlist.forEach((item) => item.addEventListener('click', (e) => this.clickPlaylistItem(e)));
  }

  clickPlaylistItem(e) {
    e.preventDefault();
    const target = e.target.closest('.playlist_item');
    const { id } = target.dataset;
    this.activatePlaylistItem(target);
    this.setVideo(id);
    target.blur();
  }

  getVideoById(id) {
    return this.videos.filter((video) => video.id === id)[0];
  }

  activatePlaylistItem(item) {
    this.deactivateAllPlaylistItems();
    item.classList.add('active');
  }

  deactivateAllPlaylistItems() {
    this.playlist.forEach((item) => item.classList.remove('active'));
  }

  renderAlert(message, status = 'error') {
    return `<div class="alert ${status}"><p>${message}</p></div>`;
  }

  renderError() {
    this.target.innerHTML = this.renderAlert(this.loc.error);
    this.target.dataset.loaded = true;
  }

  async setVideo(id) {
    const video = this.getVideoById(id);
    this.resetVideo();
    this.renderTitleLink(video);
    this.renderDescription(video);
    this.renderVideo(id);
  }

  async renderVideo(idYouTube) {
    if (!BrockmanVideoPlayer.isTargetingAllowed() || !this.adsEnabled) {
      this.renderIframe(`https://www.youtube-nocookie.com/embed/${idYouTube}`);
      return;
    }

    if (this.playoutBBVMS && this.typeBBVMS) {
      const idBBVMS = await this.searchBBVMS(idYouTube);
      if (idBBVMS) {
        this.renderScript(`https://gamernetwork.bbvms.com/p/${this.playoutBBVMS}/${this.typeBBVMS}/${idBBVMS}.js`);
        return;
      }
    }

    this.renderIframe(`https://www.youtube.com/embed/${idYouTube}`);
  }

  async searchBBVMS(idYouTube) {
    if (!idYouTube || !this.endpointBBVMS) return null;
    const endpoint = `${this.endpointBBVMS}?query=%22${idYouTube}%22&context=all`;
    try {
      const response = await fetch(endpoint);
      const json = await response.json();
      return BrockmanVideoPlayer.getIdBBVMS(json);
    } catch (e) {
      return null;
    }
  }
}
