import { getCookie, setCookie } from './cookies';

export default class BrockmanSurvey {
  constructor({
    targetSelector,
    betweenParagraphs,
    paragraphsFromEnd,
    embedURL,
    cookieName,
    cookieExpires,
    loc,
    logo,
  }) {
    this.targetSelector = targetSelector;
    this.betweenParagraphs = betweenParagraphs;
    this.paragraphsFromEnd = paragraphsFromEnd;
    this.embedURL = embedURL;
    this.loc = loc || {
      close: 'Close',
      launcherButton: 'Take our 1 minute survey',
      launcherHeading: 'Help make this site better',
    };
    this.cookieName = cookieName || 'brockman_survey';
    this.cookieExpires = cookieExpires || 30; // days
    this.logo = logo || null; // image URL

    this.classes = {
      close: 'close',
      closeButton: 'button',
      header: 'survey-header',
      launcher: 'survey-launcher',
      launcherButton: 'button',
      launcherHeading: 'survey-launcher-heading',
      lightbox: 'lightbox_fullscreen',
      logo: 'survey-logo',
      modal: 'survey-modal',
      banner: 'banner',
    };

    this.scrollController = null;
    this.modal = null;
    this.target = null;

    this.run();
  }

  run() {
    if (!this.targetSelector || !this.embedURL) return;

    if (this.getCookie()) return;

    this.target = document.querySelector(this.targetSelector);
    if (!this.target) return;

    if (this.betweenParagraphs) this.insertBetweenParagraphs();
    else this.insertDirectly();
  }

  insertBetweenParagraphs() {
    const paragraphs = this.target.querySelectorAll('p');
    const n = paragraphs.length - this.paragraphsFromEnd - 1;
    if (n < 1) return;
    const insertBefore = paragraphs[n];
    insertBefore.parentNode.insertBefore(this.renderLauncher(false), insertBefore);
  }

  insertDirectly() {
    this.target.appendChild(this.renderLauncher(true));
  }

  renderLauncher(isBanner) {
    const launcher = document.createElement(isBanner ? 'div' : 'aside');
    launcher.classList.add(this.classes.launcher);
    if (isBanner) launcher.classList.add(this.classes.banner);

    launcher.innerHTML = `
      <p class="${this.classes.launcherHeading}">${this.loc.launcherHeading}</p>
      <button class="${this.classes.launcherButton}">${this.loc.launcherButton}</button>
    `;

    const button = launcher.querySelector('button');
    button.addEventListener('click', () => this.open());

    return launcher;
  }

  open() {
    const lightbox = document.createElement('div');
    lightbox.classList.add(this.classes.lightbox);
    lightbox.addEventListener('click', () => this.close());

    const wrapper = document.createElement('div');
    wrapper.classList.add(this.classes.modal);
    wrapper.addEventListener('click', (e) => e.stopPropagation());

    const header = document.createElement('div');
    header.classList.add(this.classes.header);

    const closeButton = document.createElement('button');
    closeButton.classList.add(this.classes.closeButton, this.classes.close);
    closeButton.setAttribute('aria-label', this.loc.close);
    closeButton.addEventListener('click', () => this.close());

    const iframe = document.createElement('iframe');
    iframe.src = this.embedURL;
    iframe.addEventListener('load', (e) => e.target.contentWindow.focus());

    this.renderLogo(header);
    header.appendChild(closeButton);
    wrapper.appendChild(header);
    wrapper.appendChild(iframe);
    lightbox.appendChild(wrapper);
    document.body.appendChild(lightbox);

    this.modal = lightbox;
  }

  renderLogo(parent) {
    if (!this.logo) return;
    const logo = document.createElement('img');
    logo.classList.add(this.classes.logo);
    logo.src = this.logo;
    parent.append(logo);
  }

  close() {
    this.setCookie();
    this.modal.remove();
  }

  getCookie() {
    return getCookie(this.cookieName);
  }

  setCookie() {
    setCookie(this.cookieName, true, this.cookieExpires);
  }
}
